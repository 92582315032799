import React from 'react'
import styled from 'styled-components'
//https://medium.com/@colebemis/building-a-checkbox-component-with-react-and-styled-components-8d3aa1d826dd

const CheckboxContainer = styled.div`
  display: flex;
  color:${props => props.checked ? '#898687' : '#898687'};
  background: ${props => props.checked ? '#d6ead7' : 'white'};
  border: solid 0.5px #898687;
  padding: 0px 3px;
  border-radius: 3px;
  text-transform: uppercase;
  letter-spacing: 0px;
  font-size: 0.45rem;
  font-weight: 600;
  line-height: 165%;
  margin: 0 3px;

`

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const Checkbox = ({ checked, tag, ...props }) => (
  <CheckboxContainer checked={checked}>
    <HiddenCheckbox checked={checked} {...props} />
    <span>{tag}</span>
  </CheckboxContainer>
)

export default Checkbox
